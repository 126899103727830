// Migrated
<template lang="pug">
.position-relative.d-flex
  .position-absolute.h-100.w-100.top-0.left-0.z-3.pe-none(
    ref="shadow"
    :class="shadowClasses"
  )
  .overflow-auto.h-max-100(
    ref="container"
    :class="containerClasses"
  )
    slot
</template>

<script>
export default defineNuxtComponent({
  props: {
    axis: {
      type: String,
      default: 'y'
    },

    scrollFadeBeginPx: {
      type: Number,
      default: 50
    },

    fadeShadowSizePx: {
      type: Number,
      default: 100
    },

    fadeShadowSpread: {
      type: Number,
      default: 50
    },

    fadeColor: {
      type: String,
      default: 'white'
    },

    containerClasses: {
      type: [String, Object, Array],
      default: ''
    },

    shadowClasses: {
      type: [String, Object, Array],
      default: ''
    },
  },

  data () {
    return {
      initialized: false,
      startFadeValue: false,
      endFadeValue: false,
    }
  },

  mounted () {
    this.fadeFarOverflowEnding()
    this.initOnScroll()
  },

  updated () {
    this.fadeFarOverflowEnding()
    this.initOnScroll()
  },

  beforeUnmount () {
    this.$refs.container.removeEventListener('scroll', this.fadeFarOverflowEnding)
  },

  methods: {
    initOnScroll () {
      if (this.initialized || !process.browser || !this.$refs.container) {
        return
      }

      this.$refs.container.addEventListener('scroll', this.fadeFarOverflowEnding)
      this.initialized = true
    },

    fadeFarOverflowEnding () {
      if (!process.browser || !this.$refs.container) {
        return
      }

      const fadeValue = (scrollPos, compareScroll) => {
        let value = scrollPos / compareScroll

        if (value > 1) {
          value = 1
        }

        return value * this.fadeShadowSizePx
      }

      const scrollDirection = this.axis === 'y' ? 'Top' : 'Left'
      const scrollPos = this.$refs.container[`scroll${scrollDirection}`]
      const scrollPosMax = this.$refs.container[`scroll${scrollDirection}Max`]

      let startFadeValue = this.fadeShadowSizePx
      if (scrollPos < this.scrollFadeBeginPx) {
        startFadeValue = fadeValue(scrollPos, this.scrollFadeBeginPx)
      }

      let endFadeValue = this.fadeShadowSizePx
      if (scrollPos + this.scrollFadeBeginPx > scrollPosMax) {
        endFadeValue = fadeValue(scrollPosMax - scrollPos, this.scrollFadeBeginPx)
      }

      if (this.axis === 'y') {
        this.$refs.shadow.style.boxShadow = `
          inset 0 ${startFadeValue}px ${this.fadeShadowSpread}px -${this.fadeShadowSpread}px ${this.fadeColor},
          inset 0 -${endFadeValue}px ${this.fadeShadowSpread}px -${this.fadeShadowSpread}px ${this.fadeColor}
        `
      } else {
        this.$refs.shadow.style.boxShadow = `
          inset ${startFadeValue}px 0 ${this.fadeShadowSpread}px -${this.fadeShadowSpread}px ${this.fadeColor},
          inset -${endFadeValue}px 0 ${this.fadeShadowSpread}px -${this.fadeShadowSpread}px ${this.fadeColor}
        `
      }
    }
  }
})
</script>
